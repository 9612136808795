// This rule is deprecated so will always return mismatch

import Rule, { IRule, IRuleState } from './rule'

export default class SalesForceAudience extends Rule implements IRule {
    public getState(): Promise<IRuleState> {
        const state: IRuleState = {
            isValid: false
        }
        return Promise.resolve(state)
    }
}