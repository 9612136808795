import Rule from './rule'
import { ITargetingRulesEvaluationResult } from './experience'
import { Possibilities, Conditions } from './types'
import { isString } from './predicates'
import { experiences } from './store'
export const casperMismatchEvent = 'casper-mismatch-'

export default class XpMismatches extends Rule {
  private callback: () => void
  private lastXpName: string | null = null
  private xpToWatch: string
  private boundListener: ((event: Event) => void) | null = null
  constructor(
    callback: () => Promise<ITargetingRulesEvaluationResult>,
    predicates: Conditions
  ) {
    super()
    this.callback = callback
    if (predicates.length !== 1) {
      throw new Error(
        'Error - Casper - xpHasMismatched requires 1 argument but found ' +
          predicates.length.toString()
      )
    }
    if (!isString(predicates[0])) {
      throw new Error(
        'Error - Casper - xpHasMismatched requires argument of type string'
      )
    }
    this.xpToWatch = predicates[0]
    if (experiences[this.xpToWatch] && experiences[this.xpToWatch].mismatched) {
      this.logDebug(
        'mismatches("' +
          this.xpToWatch +
          '") the targeted Experience has already mismatched'
      )
      this.lastXpName = this.xpToWatch
    } else {
      this.logDebug(
        'mismatches("' +
          this.xpToWatch +
          '") - the targeted Experience hasn\'t already mismatched, listening'
      )
      try {
        this.boundListener = this.onXpMismatches.bind(this)
        document.addEventListener(
          casperMismatchEvent + this.xpToWatch,
          this.boundListener
        )
      } catch (e) {
        // prevent error
      }
    }
  }
  public getState(): XpMismatchState {
    const res: XpMismatchState = {
      isValid: this.validate(([this.lastXpName] as unknown) as Possibilities),
    }
    if (this.lastXpName !== null) {
      res.xpName = this.lastXpName
    }
    return res
  }
  private onXpMismatches(event: Event): void {
    this.lastXpName = (event as XPEvent).xpName
    this.logDebug(
      'mismatches("' +
        this.xpToWatch +
        '") - the targeted Experience just mismatched'
    )
    this.callback()
  }
  public off(): void {
    if (this.boundListener !== null) {
      document.removeEventListener(
        casperMismatchEvent + this.xpToWatch,
        this.boundListener
      )
    }
  }
}

interface XpMismatchState {
  isValid: boolean
  xpName?: string
}

export interface XPEvent extends Event {
  xpName: string
}
