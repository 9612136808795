import { debugMode } from './debugMode'
import { allOf, isObject, isPredicate, isString } from './predicates'
import { Conditions, Predicate, Possibilities } from './types'

export default class Rule {
  protected predicates: Predicate[] = []

  public addPredicates(conditions: Conditions): void {
    const primitiveConditions: Conditions = conditions.filter(
      (predicate) =>
        (isObject(predicate) && Object.keys(predicate).length) ||
        isString(predicate)
    )
    const functionConditions: Predicate[] = conditions.filter(isPredicate)
    if (primitiveConditions.length) {
      this.predicates.push(allOf(...primitiveConditions))
    }
    this.predicates.push(...functionConditions)
  }
  protected validate(possibilities: Possibilities): boolean {
    return this.predicates.every((predicate: Predicate) => {
      const isValid = predicate(possibilities)
      this.logDebug(
        'Rule.validate() - possibilities : ' +
          JSON.stringify(possibilities) +
          ' - isValid : ' +
          isValid.toString()
      )
      return isValid
    })
  }
  get hasPredicates(): boolean {
    return this.predicates.length > 0
  }
  protected logDebug(message: string): void {
    debugMode && console.log('Casper Debug - ' + message)
  }
}

export interface IRule {
  off?: () => Promise<void> | void
  getState: () => Promise<IRuleState> | IRuleState
}

export interface IRuleState {
  isValid: boolean
}
