import {
  ArrayTestMethods,
  DurationUnit,
  IUnknownObject,
  Possibilities,
  Predicate,
  Conditions,
  Condition,
  Possibility,
} from './types'

export function isPredicate(value: Condition): value is Predicate {
  return typeof value === 'function'
}

export function isObject(value: Condition): value is IUnknownObject {
  if (
    !(value instanceof Object || (typeof value === 'object' && value !== null))
  ) {
    return false
  }
  const proto = Object.getPrototypeOf(value) as IUnknownObject
  return proto === Object.prototype || proto === null
}

export function isString(value: Condition): value is string {
  return (
    typeof value === 'string' ||
    Object.prototype.toString.call(value) === '[object String]'
  )
}

function isEqual(expected: Condition): (value: Possibility) => boolean {
  return (value: Possibility) => expected === value
}

export const parseCondition = (
  condition: Condition,
  possibility: Possibility,
  res: boolean[] = []
): boolean[] => {
  if (isPredicate(condition) || isString(condition)) {
    res.push(evaluate(condition, possibility))
    return res
  }
  for (const property of Object.keys(condition)) {
    if ((possibility as IUnknownObject)[property] === undefined) {
      throw new SubConditionIsFalse()
    }
    const childConditionValue: Condition = (condition as IUnknownObject)[
      property
    ]!
    if (isObject(childConditionValue)) {
      if (isObject((possibility as IUnknownObject)[property]!)) {
        res.push(
          ...parseCondition(
            childConditionValue,
            (possibility as IUnknownObject)[property]!
          )
        )
      } else {
        throw new SubConditionIsFalse()
      }
    } else {
      res.push(
        evaluate(
          childConditionValue,
          (possibility as IUnknownObject)[property]!
        )
      )
    }
    if (res[res.length - 1] === false) {
      throw new SubConditionIsFalse()
    }
  }
  return res
}

export const evaluate = (
  condition: Condition,
  possibility: Possibility
): boolean => {
  if (isPredicate(condition)) {
    return condition(Array.isArray(possibility) ? possibility : [possibility])
  } else if (Array.isArray(possibility)) {
    return possibility.some((valueItem) => isEqual(condition)(valueItem))
  } else {
    return isEqual(condition)(possibility)
  }
}

export const buildPredicates = (
  testName: ArrayTestMethods,
  conditions: Conditions,
  exclude = false
): ((possibilities: Possibilities) => boolean) => {
  return (possibilities: Possibilities): boolean => {
    if (exclude && conditions.length === 0) {
      return possibilities.length === 0
    }
    return conditions[testName]((condition: Condition) =>
      possibilities.some((possibility) => {
        try {
          return parseCondition(condition, possibility).every((test) => test)
        } catch (e) {
          if (!(e instanceof SubConditionIsFalse)) {
            console.log(e)
          }
          return false
        }
      })
    )
      ? !exclude
      : exclude
  }
}

export function allOf(...conditions: Conditions): Predicate {
  return buildPredicates('every', conditions)
}

export function oneOf(...conditions: Conditions): Predicate {
  return buildPredicates('some', conditions)
}

export function noneOf(...conditions: Conditions): Predicate {
  return buildPredicates('some', conditions, true)
}

export function any(): Predicate {
  return (possibilities: Possibilities): boolean =>
    possibilities.some((possibility) => possibility)
}

export function regex(expected: RegExp): Predicate {
  return (possibilities: Possibilities): boolean =>
    possibilities.some((possibility) => expected.test(possibility as string))
}

export function isGreaterThanOrEqual(expected: number): Predicate {
  return (possibilities: Possibilities): boolean =>
    possibilities.some((possibility) => possibility >= expected)
}

export function isLessThanOrEqual(expected: number): Predicate {
  return (possibilities: Possibilities): boolean =>
    possibilities.some((possibility) => possibility <= expected)
}

export function olderThan(duration: number, unit: DurationUnit): Predicate {
  const day = 1000 * 3600 * 24
  const units: TimeUnit = {
    days: day,
    months: day * 30.4167,
    weeks: day * 7,
    years: day * 365,
  }
  if (Object.keys(units).indexOf(unit) === -1) {
    throw new Error(
      'Casper Event Hub - predicate error - olderThan - unit ' +
        unit +
        ' is not one of ' +
        Object.keys(units).join(',')
    )
  }
  return (possibilities: Possibilities): boolean =>
    possibilities.some(
      (possibility) =>
        Date.now() >
        new Date(possibility as string).getTime() + units[unit] * duration
    )
}

export class SubConditionIsFalse extends Error {}
interface TimeUnit {
  days: number
  months: number
  weeks: number
  years: number
}
