import { initStore } from './store'
import SessionTime from './session-time'

initStore()

SessionTime.getInstance()

export {
  allOf,
  oneOf,
  any,
  isGreaterThanOrEqual,
  isLessThanOrEqual,
  noneOf,
  olderThan,
  regex,
} from './predicates'

export { default as Experience } from './experience'
