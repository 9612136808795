import { initDebug } from './debugMode'
import { IJourneyPage } from './journey'
import { IUnknownObject, StorageData, ParamExperience } from './types'

export const urlParamsStorageNamespace = 'casperEventHub-urlParams'
const experiencesSessionStorageNamespace = 'casperEventHub-experiences'
export const journeySessionStorageNamespace = 'casperEventHub-journey'
let pathname: string
export let pages: IJourneyPage[]
export let experiences: IExperiences

export function initStore(): void {
  pathname = window.document.location.pathname
  pages = readFromStorage(journeySessionStorageNamespace, []) as IJourneyPage[]
  storeEntryQueryParams()
  storeJourneyPage()
  experiences = readFromStorage(
    experiencesSessionStorageNamespace,
    {}
  ) as IExperiences
  Object.keys(experiences).forEach(
    (key) => (experiences[key].mismatched = false)
  )
  initDebug()
}

export function clearStore(): void {
  window.sessionStorage.removeItem(journeySessionStorageNamespace)
  window.sessionStorage.removeItem(urlParamsStorageNamespace)
  window.sessionStorage.removeItem(experiencesSessionStorageNamespace)
}

export default class ExperienceStore {
  public triggered = false
  public mismatched = false
  public name: string
  public noTracking: boolean  
  private pageUrl = getStableHomePath(pathname)

  constructor(name: string, param?: ParamExperience) {
    this.noTracking = param?.noTracking as boolean
    if (experiences[name]) {
      const experience = experiences[name]
      if (experience.pageUrl === this.pageUrl) {
        this.name = experience.name
        this.triggered = experience.triggered
        // mismatched must remain at the per pageview level and class
        // prop won't be saved in experiences until next evaluate()
        experience.mismatched = false
      } else {
        this.name = name
      }
    } else {
      this.name = name
      this.save()
    }
  }
  public setTriggered(): void {
    this.triggered = true
    this.mismatched = false
    this.save()
  }
  public setMismatched(): void {
    this.mismatched = true
    this.save()
  }
  private save() {
    experiences[this.name] = this
    saveToStorage(experiencesSessionStorageNamespace, experiences)
  }
}

export function getStableHomePath(path: string): string {
  path = path.substr(0, 7) === '/mosaic' ? path.substr(8) : path.substr(1)
  if (path.substr(-1) === '/') {
    path = path.slice(0, -1)
  }
  return (
    path +
    (path.length === (path.substr(0, 4) === 'pro/' ? 9 : 5) ? '/home' : '')
  )
}

function readFromStorage(
  namespace: string,
  defaultValue: IJourneyPage[] | IExperiences
): IJourneyPage[] | IExperiences {
  const storage = window.sessionStorage.getItem(namespace)
  return storage
    ? (JSON.parse(storage) as IJourneyPage[] | IExperiences)
    : defaultValue
}

function storeJourneyPage() {
  pages.push({
    pageName:
      window[window.config.padl.namespace].dataLayer.page.page.pageInfo
        .pageName,
    time: 0,
    url: getStableHomePath(pathname),
  } as IJourneyPage)
  saveToStorage(journeySessionStorageNamespace, pages)
}

export function storeEntryQueryParams(): void {
  const sessionParams = window.sessionStorage.getItem(urlParamsStorageNamespace)
  if (sessionParams === null) {
    const currentParams = readParams()
    window.sessionStorage.setItem(
      urlParamsStorageNamespace,
      JSON.stringify(currentParams)
    )
  }
}

export function readParams(): IUnknownObject {
  if (window.location.search === '') {
    return {}
  }
  const queryParams = parseQueryString(window.location.search)
  if (window.location.hash !== '') {
    const hashParams = parseQueryString(window.location.hash)
    return { ...hashParams, ...queryParams }
  } else {
    return queryParams
  }
}

function parseQueryString(queryString: string | undefined): IUnknownObject {
  if (queryString == null) return {}
  return queryString
    .slice(1)
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, pair) => {
      const [key, value] = pair.map(decodeURIComponent)
      return { ...obj, [key]: value ? value : true }
    }, {})
}

export interface IExperiences {
  [name: string]: ExperienceStore
}

export function saveToStorage(namespace: string, data: StorageData): void {
  window.sessionStorage.setItem(namespace, JSON.stringify(data))
}
