import { readParams } from './store'

export let debugMode = false

export function initDebug(): void {
  const params = readParams()
  if (params.debugCasper) {
    debugMode = true
  }
}
