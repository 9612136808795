import { journeySessionStorageNamespace, pages, saveToStorage } from './store'

export default class SessionTime {
  public static getInstance(): SessionTime {
    if (!SessionTime.instance) {
      SessionTime.allowInstanciation = true
      SessionTime.instance = new SessionTime()
      SessionTime.allowInstanciation = false
    }
    return SessionTime.instance
  }
  protected static instance: SessionTime
  protected static allowInstanciation = false
  protected timeEllapsedCallbacks: ITimeEllapsedCallback[] = []
  protected start = 0
  protected checkCallbackIntervalId?: number
  private checkCallbacksIntervalMs = 1000

  constructor() {
    if (!SessionTime.allowInstanciation) {
      throw new Error("can't use Singleton constructor, use getInstance")
    }
    this.startTimer()
    window.addEventListener('beforeunload', () => {
      pages[pages.length - 1].time = this.timeInMilliseconds
      saveToStorage(journeySessionStorageNamespace, pages)
    })
  }

  get timeInMilliseconds(): number {
    return window.performance.now() - this.start
  }

  public addTimeEllapsedCallback(
    timeEllapsedCallback: ITimeEllapsedCallback
  ): void {
    timeEllapsedCallback.pending = true
    this.timeEllapsedCallbacks.push(timeEllapsedCallback)
  }

  public removeTimeEllapsedCallback(
    timeEllapsedCallback: ITimeEllapsedCallback
  ): void {
    this.timeEllapsedCallbacks.splice(
      this.timeEllapsedCallbacks.indexOf(timeEllapsedCallback),
      1
    )
  }

  public startTimer(): void {
    if (!this.checkCallbackIntervalId) {
      this.checkCallbacksOnInterval()
    }
    this.start = window.performance.now()
  }

  private onTimePassed() {
    this.timeEllapsedCallbacks.forEach((timeEllapsedCallback) => {
      if (
        timeEllapsedCallback.pending &&
        timeEllapsedCallback.time <= this.timeInMilliseconds
      ) {
        timeEllapsedCallback.callback()
        timeEllapsedCallback.pending = false
      }
    })
  }

  private checkCallbacksOnInterval() {
    this.checkCallbackIntervalId = window.setInterval(
      () => this.onTimePassed(),
      this.checkCallbacksIntervalMs
    )
  }
}

export interface ITimeEllapsedCallback {
  callback: () => void
  time: number
  pending?: boolean
}
